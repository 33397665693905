export default class Branch {
    public Name: string;

    public Nummer: number;

    constructor() {
        this.Nummer = 0;
        this.Name='';
    }
}
