import CardAction from "@/assets/models/CardAction";

export default class Card {
    NUMMER: number = -1;
    ID: string | undefined;
    STATUS!: CardStateType;
    STATUSNAME: string | undefined;
    INHABERNAME: string | undefined;
    FILIALE: number = -1;
    FILIALNAME: string | undefined;
    KUNDE: number = -1;
    ADRESSE: number = -1;
    MITARBEITER: number = -1;
    PERSONALNUMMER: number = -1;
    KARTENBEZEICHNUNG: string | undefined;
    UMSATZGESAMT: number = -1;
    GUTHABEN: number = -1;
    ANZAHLBONUSPUNKTE: number = -1;
    GUTHABENGESAMT: number = -1;
    DATUMERSTELLUNG: string | undefined;
    DATUMAUSGABE: string | undefined;
    GUELTIGAB: string | undefined;
    GUELTIGBIS: string | undefined;
    KONFIGURATION: CardKonfiguration = new CardKonfiguration();
    AKTIONEN:any[]=[];
}
export enum CardType {
    customer = "Customer",
    coupon = "Coupon",
    employee = "Employee"
}
export enum CardStateType {
    erstellt = 1,
    gedruckt,
    ausgegeben,
    ungueltig,
    aktiv,
    gesperrt,
    geloescht
}

export class CardKonfiguration {
    TYP: number = -1;
    QRDOMAIN: string | undefined;
    LAYOUT: string | undefined;
    KUNDEERFORDERLICH: boolean = false;
    ADRESSEERFORDERLICH: boolean = false;
    MAERFORDERLICH: boolean = false;
    EMAILERFORDERLICH: boolean = false;
    EINZELKARTE: boolean = false;
    GUTHABENLIMIT: number = 0;
    FESTWERT: number = 0;
    TEILEINLOESUNG: boolean = false;
    AUFLADBAR: boolean = false;
    BONUSPUNKTE: boolean = false;
    PINSICHERUNG: boolean = false;
    PIN:string="";
}
