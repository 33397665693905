import type {StateEntry} from "../../models/StateEntry";
import {StateParameter} from "@/assets/models/StateParameter";

export default class StateMachine {
    private static _instance: StateMachine;
    private _state: number = 0;
    private _states: StateEntry[];
    public parameter: StateParameter = new StateParameter();

    public jumpTo:any = undefined;
    private constructor() {
        this._states = [];
    }

    public static get Instance(): StateMachine {
        if (!this._instance) {
            this._instance = new StateMachine();
        }
        return this._instance;
    }

    public setStates(states: StateEntry[]): void {
        this._states = states;
        this.start();
    }

    public start(): void {
        let stateType = this._states[0].state;
        //console.log(this.jumpTo);
        if(this.jumpTo) {
            stateType = this.jumpTo.state;
        }
        this.goto(stateType);
    }

    public get state(): any {
        return this._states.find(state => state.state === this._state);
    }

    public goto(stateType: number, parameter: any = undefined): void {
        this._state = stateType;
        this.state.parameter = parameter;
    }
}
