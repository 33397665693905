import {Guid} from 'guid-typescript';
import axios, {AxiosResponse} from 'axios';
import {Mutex} from 'async-mutex';
import Repository from '@/assets/service/Repository';
import store from '@/store';
import KeepAliveResponse from '@/assets/service/responseTypes/logon/keepAliveResponse/KeepAliveResponse';
import {getSid, setSid, unsetSid} from '@/assets/utils/SessionUtils';
import {getIp} from '@/assets/utils/GuidUtil';
import User from '@/assets/models/User';

import TblUserResponse from "@/assets/service/responseTypes/logon/tblUserResponse/TblUserResponse";
import LogonResponse from "@/assets/service/responseTypes/logon/logonResponse/LogonResponse";
import ServiceErrors from "@/assets/service/responseTypes/ServiceErrors";
import GetLicenceInformationResponse from "@/assets/service/responseTypes/about/GetLicenseInformationResponse";
import GetLicenceInformationResponseEntry
    from "@/assets/service/responseTypes/about/GetLicenseInformationResponseEntry";

import Config from "@/assets/service/responseTypes/logon/configurationRespone/Config";
import Branch from "@/assets/models/Branch";
import Card from "@/assets/models/Card";
import Transaction from "@/assets/models/Transaction";
import BarCodeResult from "@/assets/models/BarCodeResult";
import {BarCodeFormat} from "@/assets/models/BarCodeFormat";
import TseObject, {PROCESSDATA, ZAHLUNG} from "@/assets/models/TseObject";
import Webservice from "@/assets/service/Webservice";

export default class ApiStore {
    public static AppId: string = 'APRO.GUTSCHEIN';

    // General Endpoints
    private static readonly setItemEndpoint: string = '/Storage/setItem';
    private static readonly getItemEndpoint: string = '/Storage/getItem';
    private static readonly removeItemEndpoint: string = '/Storage/removeItem';

    private static readonly clearStorageEndpoint: string = '/Storage/clearStorage';


    // Mutex to Cancel Requests
    private static getPermissionMutex: Mutex = new Mutex();

    // eslint-disable-next-line class-methods-use-this
    public static setService(service: string) {
        Repository.defaults.baseURL = service;
    }

    // eslint-disable-next-line class-methods-use-this
    public static getService(): string | undefined {
        return Repository.defaults.baseURL;
    }

    public static async getItem(key: string): Promise<any> {
        let returnValue = undefined;
        try {
            const sid = getSid();
            const response = await axios.post(Repository.defaults.baseURL + this.getItemEndpoint, {
                AppID: this.AppId,
                SID: sid,
                keyName: key
            });
            this.getErrors(response.data.getItem_response);
            returnValue = response.data?.getItem_response?.KeyValue;
        } catch (e) {

            throw(e);
        }
        return Promise.resolve(returnValue || '');
    }
    public static async setItem(key: string, value:string): Promise<void> {
        try {
            const sid = getSid();
            const response = await axios.post(Repository.defaults.baseURL + this.setItemEndpoint, {
                AppID: this.AppId,
                SID: sid,
                keyName: key,
                keyValue: value
            });
            this.getErrors(response.data.setItem_response);
            window.localStorage.setItem("deviceConfig","");
            window.localStorage.removeItem("deviceConfig");
        } catch (e) {
            console.log(e);
            throw(e);
        }
    }
    private static getErrors(response: any): void {
        if (response && response.ServiceErrors) {
            const serviceErrors = response.ServiceErrors;
            const error = serviceErrors.filter(value => value.ERRORRECORDID.length === 0);
            if (serviceErrors.filter(value => value.ERRORDESCRIPTION.includes('Anmeldung erforderlich')).length > 0) {
                Webservice.logout();
            } else {
                throw {
                    message: serviceErrors[0].ERRORDESCRIPTION
                };
            }
        }
    }
}
