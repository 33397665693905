
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class PersistentDialog extends Vue {
    private icon: string = "fa-info-circle";
    private title: string = "title";
    private message: string = "message";
    private color: string = "orange";
    private open: boolean = false;
    private confirmationCallback;
    private isConformation: boolean = false;

    public showWarning(title: string, message: string): void {
        this.show("fa-exclamation-triangle", title, message, "orange");
    }

    public showError(title: string, message: string): void {
        this.show("fa-exclamation-circle", title, message, "red");
    }

    public showInfo(title: string, message: string): void {
        this.show("fa-info-circle", title, message, "blue");
    }

    public showConfirmation(title: string, message: string, confirmationCallback: () => void): void {
        this.show("fa-question-circle", title, message, "", true, confirmationCallback);
    }

    public show(icon: string, title: string, message: string, color: string, isConformation: boolean = false, confirmationCallback: () => void = () => {
    }): void {
        this.icon = icon;
        this.title = title;
        this.message = message;
        this.isConformation = isConformation;
        this.confirmationCallback = confirmationCallback;
        this.color = color;
        this.open = true;
    }

    private close(): void {
        this.$emit("DialogClose")
        this.isConformation = false;
        this.open = false;
    }

    private confirm(): void {
        if (this.confirmationCallback) {
            this.confirmationCallback();
        }
        this.confirmationCallback = undefined;
        this.close();
    }
}
