/*
* CustomerCard_Info
*   - CustomerCard_Checkout
*       - CustomerCard_Checkout_Manually
*       - CustomerCard_Checkout_Overview
*           - CustomerCard_Checkout_Result
*   - CustomerCard_CollectPoints
*       - CustomerCard_CollectPoints_Manually
*           - CustomerCard_CollectPoints_Result
*   - CustomerCard_Recharge
*       - CustomerCard_Recharge_Manually
*       - CustomerCard_Recharge_Result
*/

export enum CustomerStateType {
    Info,
    Checkout,
    Checkout_Manually,
    Checkout_Overview,
    Checkout_Pin,
    Checkout_Result,
    CollectPoints,
    CollectPoints_Manually,
    CollectPoints_Result,
    Recharge,
    Recharge_Auth,
    Recharge_Manually,
    Recharge_Result,
    TransferPoints,

    DistributeCard,
    Checkout_PartialPayment,

    JumpToRecharge = 100,
    JumpToCheckout,
    JumpToCollect
}
