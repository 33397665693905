import Vue from 'vue'
import Vuex from 'vuex'
import session from "@/store/modules/session";
import customer from "@/store/modules/customer";
import outlet from "@/store/modules/outlet";
import ApiStore from "@/assets/service/ApiStore";

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        branches: [],
        users: [],
        deviceConfig: undefined,
        isInjected:  window.localStorage.getItem('isInjected') || "false",
        entryPoint:  window.localStorage.getItem('entryPoint') || "0"
    },
    getters: {
        branches: state => state.branches,
        users: state => state.users,
        deviceConfig:async (state) => {
            //console.log("get deviceConfig - Start");
            //console.log(state.deviceConfig);
            if(state.deviceConfig) {
                //console.log("get deviceConfig - state.deviceConfig not null");
                return state.deviceConfig;
            }

            const oldValueString = window.localStorage.getItem("deviceConfig")
            if(oldValueString) {
                //console.log("get deviceConfig - oldValueString not null");
                try{
                    const oldValue = JSON.parse(oldValueString);
                    try {
                        await ApiStore.setItem("deviceConfig", oldValueString);
                    }catch(e:any) {
                        state.deviceConfig = oldValue;
                    }

                }catch(e){
                    //console.log(e);
                }finally {

                }
            }
            if(!state.deviceConfig) {
                //console.log("get deviceConfig - 2 state.deviceConfig is null");
                let valueString;
                try {
                    valueString = await ApiStore.getItem("deviceConfig");
                }catch(e:any) {
                    //state.deviceConfig = <any>{useExternalScanner: false, codeType: 0};
                }
                    if (valueString) {
                        //console.log("get deviceConfig - valueString is not null");
                        state.deviceConfig = JSON.parse(valueString);
                    } else {
                        //console.log("get deviceConfig - valueString is null");
                        state.deviceConfig = <any>{useExternalScanner: false, codeType: 0};
                    }

                // console.log(state.deviceConfig);
            }
            //console.log("get deviceConfig - return state.deviceConfig");
            //console.log(state.deviceConfig);
            return state.deviceConfig;
        },
        isInjected: initState => JSON.parse(initState.isInjected),
        entryPoint: initState => JSON.parse(initState.entryPoint)
    },
    mutations: {
        SET_USERS(state, params) {
            state.users = params.users;
        },
        SET_BRANCHES(state, params) {
            state.branches = params.branches;
        },
        async SET_DEVICE_CONFIG(state, param) {
            state.deviceConfig= param;
            try {
                await ApiStore.setItem('deviceConfig',JSON.stringify(param));
            }catch(e:any) {
                window.localStorage.setItem("deviceConfig", JSON.stringify(param));
            }
            
        },
        SET_ISINJECTED(state, param) {
            state.isInjected = JSON.stringify(param);
            window.localStorage.setItem('isInjected', JSON.stringify(param));
        },
        SET_ENTRYPOINT(state, param) {
            state.entryPoint =  JSON.stringify(param);
            /*
            * Aufladen = 1
            * Zahlen = 2
            * PunkteSammeln = 3
            * */
            window.localStorage.setItem('entryPoint',  JSON.stringify(param));
        }
    },
    actions: {},
    modules: {
        outlet,
        session,
        customer
    }
});

export default store;
