import ResultWrapper from "@/assets/service/responseTypes/ServiceResults";

export default class GetLicenceInformationResponseEntry extends ResultWrapper {
    Product!: string;
    SerialNumber!: string;
    Company!: string;
    Copies!: string;
    CopiesInUse!: string;
    WebCopies!: string;
    WebCopiesInUse!: string;
    Level!: string;
    Optional!: string;
    ExpiryDate!: string;
}


