
import {Component, Prop, Vue} from "vue-property-decorator";
import ApiStore from "@/assets/service/ApiStore";


@Component({components: {}})
export default class DeviceConfig extends Vue {

    private loading: boolean=false;

    @Prop({default: true})
    private showCancel!: boolean;
    private valid: boolean = true;

    private deviceConfig: any = {};

    async mounted() {
        this.loading=true;
        await this.$store.getters.deviceConfig.then((result)=>{
            this.deviceConfig = result;
        }).finally(()=>{
            this.loading = false;
        });

    }

    private cancel(): void {
        this.$emit('cancel');
    }

    private async save(): Promise<void> {
        try {
            //window.localStorage.setItem('deviceConfig',btoa(JSON.stringify(this.deviceConfig)));
            await this.$store.commit("SET_DEVICE_CONFIG",this.deviceConfig);
            //this.$store.commit('SET_DEVICE_CONFIG', this.deviceConfig);
            this.$emit('save', this.deviceConfig);


        } catch (e: any) {
            console.error(e);
        }
    }
}