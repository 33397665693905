
import {Component, Prop, Vue} from "vue-property-decorator";
import Webservice from "@/assets/service/Webservice";
import StateMachine from "@/assets/js/bl/StateMachine";
import {CustomerStateType} from "@/assets/models/StateType";
import Repository from "@/assets/service/Repository";


@Component({components: {}})
export default class GuthabenZahlen extends Vue {
    private errorMessage:string="Leider hat etwas nicht funktioniert."
    private success: boolean = true;

    async mounted() {
        const query = {...this.$route.query};
        if (query.sid) {
            if(query.surl && query.surl !== ''){
                this.$store.commit('SET_SERVICE', {service: query.surl});
                Repository.defaults.baseURL = query.surl.toString();
            }
            if(!this.$store.getters.service) {
                await this.$router.push("/login");
            }
            try{
                const sessionInfo = await Webservice.sessionInfo(query.sid.toString());
                if(sessionInfo){
                    this.$store.commit("SET_ISINJECTED",true);
                    this.$store.commit("SET_ENTRYPOINT",2);

                    let amount = 0;
                    if(query.amount) {
                        amount = Number.parseFloat(query.amount.toString());
                    }
                    StateMachine.Instance.jumpTo={state:CustomerStateType.JumpToCheckout, amount: amount};
                    await this.$router.push("/");
                    this.success = true;
                }
            }catch(e:any) {
                this.success = false;
                this.errorMessage = e;
            }

        } else {
            await this.$router.replace("/login");
            this.success = false;
        }
    }
}