import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import VueCurrencyInput from 'vue-currency-input';
import {getSid} from "@/assets/utils/SessionUtils";
import './plugins/custom-form-components';

Vue.use(VueCurrencyInput);

Vue.config.productionTip = false
Vue.config.performance = true;

window.onfocus = () => {
    if (getSid() === '' && router.currentRoute.name !== 'Login') {
        location.reload();
    }
};
export const globalEventBus = new Vue();

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app')
