
import {Component, Vue} from "vue-property-decorator";
import Webservice from "@/assets/service/Webservice";

@Component({components: {}})
export default class End extends Vue {
    private message:string = "Sie wurden erfolgreich abgemeldet."

    mounted() {
        this.$store.commit("SET_ISINJECTED",false);
        this.$store.commit("SET_ENTRYPOINT",0);
        Webservice.logout();
        this.$router.replace("/");
    }
}