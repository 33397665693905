
import { Component, Vue } from 'vue-property-decorator';
import DeviceConfigComp from "@/components/DeviceConfig.vue";

@Component({
    components: { DeviceConfigComp },
})
export default class DeviceConfig extends Vue {
    private loading : boolean = false;

    private enterDeviceConfig(): void {
        //location.href="/";
        this.$router.push({name: 'Scan'});
    }



}
