import ServiceErrors from '@/assets/service/responseTypes/ServiceErrors';

export default class ResultWrapper {
    ServiceResults!: ServiceResults;

    ServiceErrors!: ServiceErrors[];
}

export class ServiceResults {
    ResultAction!: string;

    ResultTable!: string;

    ResultRecordID!: string;

    ResultDescription!: string;
}
