
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import {isLoggedIn} from "@/assets/utils/SessionUtils";
import Webservice from '@/assets/service/Webservice';
import PersistentDialog from "@/components/dialogs/PersistentDialog.vue";
import {globalEventBus} from "@/main";

@Component({
    components: {PersistentDialog}
})
export default class App extends Vue {
    private initialized = false;
    private keepAliveCall: NodeJS.Timer | undefined = undefined;
    private openInfoMenu: boolean = false;
    @Ref()
    private messageDialog!: PersistentDialog;
    // @Watch('$store.getters.deviceConfig')
    // private deviceConfigChanges(deviceConfig: any): void {
    //     if(!deviceConfig) {
    //         alert("DeviceConfig deleted");
    //     }
    //     alert(JSON.stringify(deviceConfig));
    // }
    @Watch('$route')
    private routeChanges(to: any): void {
        if(this.$route.meta?.loginRequired) {
            if(!this.keepAliveCall) {
                Webservice.keepAlive(() => {
                }, () => {
                    try {
                        Webservice.logout();
                    }
                    catch(e:any) {
                        this.$router.push('/login');
                    }
                });
                // test credentials every 60 seconds
                //this.keepAliveCall = setInterval(this.keepAlive, Number.parseInt(process.env.VUE_APP_KEEPALIVE_TIMEOUT || "4000"));
            }

        }else {
            if(this.keepAliveCall) {
                clearInterval(this.keepAliveCall);
                this.keepAliveCall = undefined;
            }
        }
    }

    async mounted() {
        globalEventBus.$on("showInfoDialog", this.showInfoDialog);
        globalEventBus.$on("showErrorDialog", this.showErrorDialog);
    }

    unmounted() {
        globalEventBus.$off("showInfoDialog", this.showInfoDialog);
        globalEventBus.$off("showErrorDialog", this.showErrorDialog);
        if (this.keepAliveCall) {
            clearInterval(this.keepAliveCall);
        }
    }

    isUserLoggedIn(): boolean {
        return isLoggedIn();
    }

    private logout(): void {
        if(this.$store.getters.isInjected) {
            this.$router.replace("/end");
        } else {
            Webservice.logout();
        }
    }

    private showInfoDialog(message: string) {
        this.messageDialog.showInfo("Info", "<pre style='white-space: break-spaces;'>" + message + "</pre>");
    }

    private showErrorDialog(message: string) {
        this.messageDialog.showError("Error", "<pre style='white-space: break-spaces;'>" + message + "</pre>");
    }

    private keepAlive(): void {
        if (isLoggedIn()) {
            Webservice.keepAlive(() => {
            }, () => {
                try {
                    this.logout();
                }
                catch(e:any) {
                    this.$router.push('/login');
                }
            });
        }
    }
}
