import Card from "@/assets/models/Card";
import Tse from "@/assets/models/Tse";
import {PaymentType} from "@/assets/models/PaymentType";
import User from "@/assets/models/User";

export class StateParameter {
    cardInfo: Card = new Card();
    tseInfo: Tse = new Tse();
    paymentType: PaymentType = PaymentType.WithCard;
    authUser: User | undefined;
}
