import Branch from "@/assets/models/Branch";

const state = {
    outletList: [],
    selectedOutlet: window.localStorage.getItem('selectedOutlet') ? window.localStorage.getItem('selectedOutlet') : JSON.stringify(new Branch())
};

const getters = {
    outletList: state => state.outletList,
    selectedOutlet: (state) => {
        let branch =  JSON.parse(state.selectedOutlet);
        if(!branch) {
            branch = new Branch();
        }
        return branch;
    }
};

const mutations = {
    SET_OUTLET_LIST(state, params) {
        state.outletList = params;
    },
    SET_SELECTED_OUTLET(state, param) {
        state.selectedOutlet = param;
        window.localStorage.setItem('selectedOutlet', param);
    }
};

export default {
    state,
    getters,
    mutations,
};
