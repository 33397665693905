const state = {
    service: window.localStorage.getItem('service') || '',
    activeUserId: window.localStorage.getItem('activeUserId') || 0,
    guid: window.localStorage.getItem('guid') || '',
    configuration: window.localStorage.getItem('configuration') || undefined
};

const getters = {
    service: initState => initState.service,
    guid: initState => initState.guid,
    activeUserId: initState => initState.activeUserId,
    configuration: initState => initState.configuration
};

const mutations = {
    SET_SERVICE(initState, params) {
        // eslint-disable-next-line no-param-reassign
        initState.service = params.service;
        window.localStorage.setItem('service', params.service);
        navigator.serviceWorker.ready.then((value) => {
            if (value.active) {
                value.active.postMessage({
                    transfer: [params.service],
                    command: 'updateService',
                });
            }
        });
    },
    SET_GUID(initState, params) {
        // eslint-disable-next-line no-param-reassign
        initState.guid = params.guid;
        window.localStorage.setItem('guid', params.guid);
    },
    SET_ACTIVE_USERID(initState, params) {
        // eslint-disable-next-line no-param-reassign
        initState.activeUserId = params.activeUserId;
        window.localStorage.setItem('activeUserId', params.activeUserId);
    },
    SET_CONFIGURATION(initState, param) {
        initState.configuration = param;
        window.localStorage.setItem('configuration', param);
    },

};

export default {
    state,
    getters,
    mutations,
};
