import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/src/locale/de';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#3F78B3',
                Red: '#CC4C33',
                Green: '#56AD89',
                Blue: '#3F78B3',
                Yellow: '#EAB766',
                LightRed: '#EEC4BB',
                LightGreen: '#C6E3D7',
                LightBlue: '#C0D5E9',
                LightYellow: '#F4DDB5',
                DarkRed: '#883422',
                DarkGreen: '#377359',
                DarkBlue: '#2D557D',
                DarkYellow: '#966514',
            },
            dark: {
                primary: '#3F78B3',
                Red: '#CC4C33',
                Green: '#56AD89',
                Blue: '#3F78B3',
                Yellow: '#EAB766',
                LightRed: '#EEC4BB',
                LightGreen: '#C6E3D7',
                LightBlue: '#C0D5E9',
                LightYellow: '#F4DDB5',
                DarkRed: '#883422',
                DarkGreen: '#377359',
                DarkBlue: '#2D557D',
                DarkYellow: '#966514',
            },
        },
    },
    lang: {
        locales: {de},
        current: 'de',
    },
    icons: {
        iconfont: 'fa',
    },
});
