export class BRUTTOSTEUERSAETZE {
    ALLGEMEIN?: number;
    ERMAESSIGT?: number;
    DURCHSCHNITTSATZNR3?: number;
    DURCHSCHNITTSATZNR1?: number;
    STEUERFREI?: number;
}

export class ZAHLUNG {
    BETRAG?: number;
    ZAHLUNGSFORM?: string;
    WAEHRUNG?: string;
}


export class PROCESSDATA {
    TRANSAKTIONSTYP?: string;
    BRUTTO_STEUERSAETZE?: BRUTTOSTEUERSAETZE;

    ZAHLUNG_1?: ZAHLUNG;
    ZAHLUNG_2?: ZAHLUNG;
    ZAHLUNG_3?: ZAHLUNG;
    ZAHLUNG_4?: ZAHLUNG;
    ZAHLUNG_5?: ZAHLUNG;


}

export default class TseObject {
    QR_CODE_VERSION?: string;
    KASSEN_SERIENNUMMER?: string;
    PROCESSTYPE?: string;
    PROCESSDATA?: PROCESSDATA;
    TRANSAKTIONS_NUMMER?: number;
    SIGNATUR_ZAEHLER?: number;
    START_ZEIT?: Date;
    LOG_TIME?: Date;
    SIG_ALG?: string;
    LOG_TIME_FORMAT?: string;
    SIGNATUR?: string;
    PUBLIC_KEY?: string;


}
