import Vue from 'vue'
import VueRouter, {RawLocation, Route, RouteConfig} from 'vue-router'
import Login from "@/views/Login.vue";
import DeviceConfig from "@/views/DeviceConfig.vue";
import {isLoggedIn} from "@/assets/utils/SessionUtils";
import store from '../store';
import StartApp from "@/views/StartApp.vue";
import GuthabenZahlen from "@/views/GuthabenZahlen.vue";
import GuthabenAufladen from "@/views/GuthabenAufladen.vue";
import End from "@/views/End.vue";
import PunkteSammeln from "@/views/PunkteSammeln.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: "/",
        redirect: "/scan",
    },
    {
        path: "/scan",
        name: 'Scan',
        component: () => {
            //@ts-ignore
            return import("@/views/Scan.vue")
        },
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => {
            //@ts-ignore
            return import("@/views/Login.vue")
        },
    },
    {
        path: '/cardInfo',
        name: 'cardInfo',
        component: () => {
            //@ts-ignore
            return import("@/views/CardInfo.vue")
        },
    },
    {
        path: '/deviceConfig',
        name: 'DeviceConfig',
        component: DeviceConfig,
        meta: {
            loginRequired: true,
            pageTitle: 'Geräteeinstellungen',
        },
    },
    {
        path: '/start',
        name: 'start',
        component: StartApp,
        meta: {
            loginRequired: false,
            pageTitle: 'Start',
        },
    },
    {
        path: '/guthaben_info',
        name: 'guthaben_info',
        component: StartApp,
        meta: {
            loginRequired: false,
            pageTitle: 'Guthaben Info',
        },
    },
    {
        path: '/guthaben_zahlen',
        name: 'guthaben_zahlen',
        component: GuthabenZahlen,
        meta: {
            loginRequired: false,
            pageTitle: 'Guthaben zahlen',
        },
    },
    {
        path: '/guthaben_aufladen',
        name: 'guthaben_aufladen',
        component: GuthabenAufladen,
        meta: {
            loginRequired: false,
            pageTitle: 'Guthaben aufladen',
        },
    },
    {
        path: '/punktesammeln',
        name: 'punktesammeln',
        component: PunkteSammeln,
        meta: {
            loginRequired: false,
            pageTitle: 'Punkte sammeln',
        },
    },
    {
        path: '/end',
        name: 'end',
        component: End,
        meta: {
            loginRequired: false,
            pageTitle: 'By bye.',
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to: Route,
                   from: Route,
                   next: (to?: RawLocation | false | void) => void) => {

    if (to.matched.some(record => record.meta.loginRequired)) {
        if (!isLoggedIn()) {
            if (to.query.service) {
                store.commit('SET_SERVICE', {service: decodeURIComponent(to.query.service as string)});
            }
            next({name: 'Login'});
        } else if (to.name === 'Login') {
            next({name: 'Scan'});
        } else {
            next();
        }
    } else if (to.name === 'Login' && isLoggedIn()) {
        next({name: 'Scan'});
    } else {
        next();
    }
});

export default router
