/* eslint-disable no-console */

import {register} from 'register-service-worker'
import router from "@/router";

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            //console.log(
            //    'App is being served from cache by a service worker.\n' +
            //    'For more details, visit https://goo.gl/AFskqB'
            //)
        },
        registered() {
            // clean up old SW caches
            caches.keys().then(function (cacheNames) {
                return Promise.all(
                    cacheNames.map(function (cacheName) {
                        return caches.delete(cacheName);
                    }));
            });
        },
        cached() {
            //console.log('Content has been cached for offline use.')
        },
        updatefound() {
            router.go(0);
        },
        updated() {

        },
        offline() {
           // console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
