const state = {
    customerBalances: null,
};

const getters = {
    customerBalances: state => state.customerBalances,
};

const mutations = {
    SET_CUSTOMER_BALANCE(state, param) {
        state.customerBalances = param;
    }
};

export default {
    state,
    getters,
    mutations,
};
