/*
 * General utils for managing cookies in Typescript.
 */

/**
 * Set or update a new Cookie
 * @param name Name of the Cookie
 * @param val Value of the Cookie
 * @param duration Lifetime of the Cookie in minutes
 */
export function setCookie(name: string, val: string, duration: number): void {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (duration * 60 * 1000));

    // Set it
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

/**
 * Get the value of a Cookie
 * @param name Name of the Cookie
 * @return The value of the Cookie
 */
export function getCookie(name: string): string {
    const value = `; ${document.cookie}`;
    const parts: string[] = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return (parts.pop() as string).split(';').shift() as string;
    }
    return '';
}

/**
 * Delete a Cookie by setting the expiration to -1 days
 * @param name
 */
export function deleteCookie(name: string): void {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
}
