import {deleteCookie, getCookie, setCookie} from '@/assets/utils/CookieUtils';

let sessionHandler: number | undefined;

export function getSid(): string {
    return getCookie('sid');
}

export function setSid(sid: string) {
    if (sessionHandler !== undefined) {
        window.clearTimeout(sessionHandler);
    }
    setCookie('sid', sid, 15);
    sessionHandler = window.setTimeout(timeoutHandle, 15 * 60 * 1000);
}

export function unsetSid() {
    if (sessionHandler !== undefined) {
        window.clearTimeout(sessionHandler);
    }
    deleteCookie('sid');
}

export function isLoggedIn(): boolean {
    return getSid() !== null && getSid() !== '';
}

const timeoutHandle = () => {
    location.reload();
};
